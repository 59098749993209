@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        @apply h-full;
    }
    body {
      @apply bg-gray-100;
      @apply h-full;
    }

    mark {
        @apply inline-block pr-2 pl-2 py-1;
    }
    
    .mark-rounded {
        @apply rounded-sm;
    }
    
    .mark-blue {
        @apply bg-blue-100;
    }

    .mark-red {
        @apply bg-red-100;
    }
  }

@layer utilities {
    .pb-safe-4 {
        padding-bottom: max(env(safe-area-inset-bottom), 1rem);
    }

    .pb-safe-40 {
        padding-bottom: max(env(safe-area-inset-bottom) + 9rem, 10rem); 
    }
    .mb-safe {
        margin-bottom: max(env(safe-area-inset-bottom), 0);
    }
    
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

